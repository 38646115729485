import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image'

// Elements
import ButtonDefault from 'components/elements/ButtonDefault'

// Third Party
import styled from 'styled-components'

// Images
import arrow from 'img/prof_arrow.svg'

const Content = styled(ParseContent)`
  h2 {
    font-size: ${props => props.theme.font.size.xxxxml};
    color: ${props => props.theme.color.text.third};
    margin-bottom: 30px;

    @media screen and (max-width: 991px) {
      font-size: ${props => props.theme.font.size.xxxl};
      margin-bottom: 0;
      margin-top: 20px;
    }
  }
  
  p {
    font-family: ${props => props.theme.font.family.secondary};
    line-height: 35px;
    color: ${props => props.theme.color.text.dark};

    @media screen and (max-width: 991px) {
      font-size: ${props => props.theme.font.size.sm};
      line-height: 30px;
    }
  }
`

const StyledProfessors = styled.div``

const Title = styled.h2`
  font-size: ${props => props.theme.font.size.xxxxl};
  text-align: center;
  position: relative;

  @media screen and (max-width: 991px) {
    font-size: ${props => props.theme.font.size.xxxl};
  }

  &::before {
    content: "";
    position: absolute;
    background-color: #313131;
    height: 1px;
    width: 280px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    left: -340px;

    @media screen and (max-width: 991px) {
      left: -300px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    background-color: #313131;
    height: 1px;
    width: 280px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    right: -340px;

    @media screen and (max-width: 991px) {
      right: -300px;
    }
  }
`

const ImageContainer = styled.div`
  box-shadow: 0 0 20px rgba(0,0,0,0.3);
`

const Professors = ({ fields, className }) => {
  return (
    <StyledProfessors className={`container ${className ? `${className}` : ``}`}>
      <div className="d-flex justify-content-center">
        <Title>Prof & Prof</Title>
      </div>
      {fields.map((p, index) => (
        <>
          {index % 2 === 0 ? (
            <div className="row flex-wrap-reverse mb-5 mb-lg-0 py-lg-5 py-3">
              <div className="col-lg-7 pt-lg-4">
                <Content content={p.description} />
                <div className="mt-4 d-flex justify-content-start">
                  <ButtonDefault className="mt-lg-5" to={p.link.url}>
                    {p.link.title}
                    <img className="ml-3" src={arrow} alt="" />
                  </ButtonDefault>
                </div>
              </div>
              <div className="col-lg-5 align-items-center d-flex">
                <ImageContainer className="w-100">
                  <Image src={p.image} />
                </ImageContainer>
              </div>
            </div>
          ) : (
            <div className="row py-lg-5 py-3">
              <div className="col-lg-5 align-items-center d-flex">
                <ImageContainer className="w-100">
                  <Image src={p.image} />
                </ImageContainer>
              </div>
              <div className="col-lg-7 pl-lg-5 pt-lg-4">
                <Content content={p.description} />
                <div className="mt-4 d-flex justify-content-lg-end justify-content-start">
                  <ButtonDefault className="mt-lg-5" to={p.link.url}>
                    {p.link.title}
                    <img className="ml-3" src={arrow} alt="" />
                  </ButtonDefault>
                </div>
              </div>
            </div>
          )}
        </>
      ))}
    </StyledProfessors>
  )
}

export default Professors