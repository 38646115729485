/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'

// Elements
import HeroDefault from 'components/elements/HeroDefault'
import Videos from 'components/elements/Videos'
import Newsletter from 'components/elements/Newsletter'
import Professors from 'components/elements/Professors'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'

const HomeTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout extraSpacing >
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <HeroDefault home link={{ title: "Bekijk onze video's", url: '/video/' }} fields={acf.banner} />

      <Videos className="py-lg-5 py-3" limit={3} />

      <Newsletter />

      <Professors fields={acf.prof_en_prof} className="py-lg-5 py-3" />

    </Layout>
  )
}

export default HomeTemplate