import React from 'react'

// Elements
import Video from 'components/elements/Video'
import ButtonDefault from 'components/elements/ButtonDefault'

// Third Party
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

const StyledVideos = styled.div``

const Title = styled.h2`
  font-size: ${props => props.theme.font.size.xxml};
`

const Videos = ({ className, limit }) => {
  let {
    videos: {
      nodes: videos
    }
  } = useStaticQuery(graphql`{
    videos: allWordpressWpVideo {
      nodes {
        ...videoPreview
      }
    }
  }`)

  if (limit) {
    videos = videos.slice(0, limit)
  }

  return (
    <StyledVideos className={`container ${className ? `${className}` : ``}`}>
      <Title className="mb-4">Prof & Filmpjes</Title>
      <div className="row pb-lg-5">
        {videos.map((v, index) => (
          <div className="col-lg-4" key={v.wordpress_id}>
            <Video delay={index * 0.3} video={v} />
          </div>
        ))}
      </div>
      {limit && (
        <div className="d-flex justify-content-center py-lg-4 py-3">
          <ButtonDefault to="/filmpjes/">
            Bekijk alle filmpjes
          </ButtonDefault>
        </div>
      )}
    </StyledVideos>
  )
}

export default Videos