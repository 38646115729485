import React from 'react'

// Components
import GravityForm from 'components/shared/GravityForm'

// Third Party
import styled from 'styled-components'

const StyledNewsletter = styled.div`
  background-color: ${props => props.theme.color.face.dark_main};
`

const Newsletter = ({ className }) => {
  return (
    <StyledNewsletter className={`py-5 ${className ? `${className}` : ``}`}>
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <p className="font-size-m font-family-secondary font-weight-xl color-light text-sm-left text-center mb-3 mb-lg-0">Wil je op de hoogte worden gehouden van nieuwe filmpjes?</p>
            <p className="font-size-m font-family-secondary font-weight-xl color-light text-sm-left text-center mb-3 mb-lg-0">Vul dan je e-mailadres in.</p>
          </div>
          <div className="col-sm-6">
            <GravityForm submitStyle="green" newsletter id={2} />
          </div>
        </div>
        
      </div>
    </StyledNewsletter>
  )
}

export default Newsletter